import { 
  GET_USER_LIST, 
  SAVE_USER_LIST, 
  GET_APP_USER_BY_ID,
  UPDATE_APP_USER_BY_ID,
  GET_HOOD_LIST_BY_USER_ID,
  SAVE_HOOD_LIST_BY_USER_ID,
  SAVE_APP_USER_BY_ID,
  GET_PLACE_LIST_BY_USER_ID,
  SAVE_PLACE_LIST_BY_USER_ID,
  DELETE_USER_BY_ID
} from "../types/action-type";

export const getUserList = (pageIndex, pageSize, searchKey) => ({
  type: GET_USER_LIST,
  pageIndex,
  pageSize,
  searchKey,
});
export const saveUserList = (data) => ({
  type: SAVE_USER_LIST,
  data,
});

export const getHoodById = (userId) => ({
  type: GET_HOOD_LIST_BY_USER_ID,
  userId,
});
export const saveHoodUsersById = (data) => {
  return{
    type: SAVE_HOOD_LIST_BY_USER_ID,
    data,
  }
};
export const getPlacesById=(userId)=>({
  type:GET_PLACE_LIST_BY_USER_ID,
  userId,
});
export const savePlacesUsersById=(data)=>({
  type:SAVE_PLACE_LIST_BY_USER_ID,
  data,
});
export const getAppUserById = (id) => ({
  type: GET_APP_USER_BY_ID,
  id,
});
export const updateAppUserById = (data, navigate) => {
  return{
    type: UPDATE_APP_USER_BY_ID,
    data,
    navigate,
  }
};
export const deleteUserById = (id) => ({
  type: DELETE_USER_BY_ID,
  id,

});
export const saveAppUserById = (data) => ({
  type: SAVE_APP_USER_BY_ID,
  data,
});
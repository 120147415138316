import { 
  GET_APP_USER_BY_ID, 
  GET_USER_LIST, 
  SAVE_APP_USER_BY_ID, 
  SAVE_USER_LIST ,
  GET_HOOD_LIST_BY_USER_ID,
  SAVE_HOOD_LIST_BY_USER_ID,
  GET_PLACE_LIST_BY_USER_ID,
  SAVE_PLACE_LIST_BY_USER_ID,
  DELETE_USER_BY_ID,
  UPDATE_APP_USER_BY_ID
} from "../types/action-type";

import _get from "lodash/get";
import { saveAppUserById, saveUserList,saveHoodUsersById,savePlacesUsersById } from "../actions/user";
import { takeLatest, call, put } from "redux-saga/effects";
import mias from "../api/index";
import { toast } from "react-hot-toast";

function* getUserList({pageIndex, pageSize, searchKey}) {
  try {
    const response = yield call(mias.apis.users.userList, pageIndex, pageSize, searchKey);
    response.data.data.pageIndex = pageIndex;
    response.data.data.pageSize = pageSize;
    yield put(saveUserList(_get(response, "data.data", [])));
  } catch (error) {
    console.log("error in getUserList", error);
  }
}

function* getAppUserById({ id }) {
  try {
    const response = yield call(mias.apis.users.getAppUserById, id);
    yield put(saveAppUserById(_get(response, "data.data", [])));
  } catch (err) {
    console.log("getAppUserById saga error ::", err);
  }
}
function* getHoodListByUserId({ userId }) {
  try {
    const response = yield call(mias.apis.users.getHoodListByUserId, userId);
    console.log("getHoodListByUserId saga response ::", response);
    yield put(saveHoodUsersById(_get(response, "data", [])));
  } catch (err) {
    console.log("getHoodListByUserId saga error ::", err);
  }
}

function* getPlacesListByUserId({ userId }) {
  try {
    const response = yield call(mias.apis.users.getPlacesListByUserId, userId);
    console.log("getPlacesListByUserId saga response ::", response);
    yield put(savePlacesUsersById(_get(response, "data", [])));
  } catch (err) {
    console.log("getPlacesListByUserId saga error ::", err);
  }
}

function* updateUserById(data){
  try{
    yield call(mias.apis.users.updateAppUser, data.data);
    data.navigate("/users")
    toast.success("Saved!")
  } catch(error){
    toast.error(_get(error, "response.data.message", _get(error, "message", "")));
    console.log("updateUserById saga error ::", error);
  }
}
function* deleteUserById({ id }) {
  try {
    const response = yield call(mias.apis.users.deleteAppUser, id);
    toast.success("User deleted successfully",5000);
    const api= yield call(mias.apis.users.userList, 1, 30, "");
    yield put(saveUserList(_get(api, "data.data", [])));
    console.log("deleteUserById saga response ::", response);
  
  } catch (err) {
    toast.error(_get(err, "response.data.message", _get(err, "message", "")));
    console.log("deleteUserById saga error ::", err);
  }
}

export default function* user() {
  yield takeLatest(GET_USER_LIST, getUserList);
  yield takeLatest(SAVE_USER_LIST, saveUserList);
  yield takeLatest(GET_APP_USER_BY_ID, getAppUserById);
  yield takeLatest(SAVE_APP_USER_BY_ID, saveAppUserById);
  yield takeLatest(GET_HOOD_LIST_BY_USER_ID, getHoodListByUserId);
  yield takeLatest(SAVE_HOOD_LIST_BY_USER_ID, saveHoodUsersById);
  yield takeLatest(GET_PLACE_LIST_BY_USER_ID, getPlacesListByUserId);
  yield takeLatest(SAVE_PLACE_LIST_BY_USER_ID, savePlacesUsersById);
  yield takeLatest(UPDATE_APP_USER_BY_ID, updateUserById);
  yield takeLatest(DELETE_USER_BY_ID, deleteUserById);
}
